import type { AppProps } from "next/app";
import Head from "next/head";
import { SWRConfig } from "swr";

import fetcher from "../utils/fetcher";
import useAnalytics from "../utils/useAnalytics";

import "../styles/globals.css";

const fonts = ["lato-latin-700", "lato-latin-900", "lato-latin-regular"];

function DeveloperPortal({ Component, pageProps }: AppProps) {
  useAnalytics();
  return (
    <SWRConfig value={{ fetcher }}>
      <Head>
        {fonts.map((font) => (
          <link
            as="font"
            crossOrigin=""
            href={`/fonts/${font}.woff2`}
            key={font}
            rel="preload"
          />
        ))}
        <link rel="shortcut icon" href="/img/geops-icon.png" />
        <title>geOps Developer Portal</title>
      </Head>
      <Component {...pageProps} />
    </SWRConfig>
  );
}

export default DeveloperPortal;
