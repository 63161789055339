const backendHost = process.env.NEXT_PUBLIC_BACKEND_HOST;

export default async function fetcher(resource: string, init: RequestInit) {
  const res = await fetch(`${backendHost}/api/v1/${resource}`, {
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    ...init,
  });

  if (res.status === 200) {
    return res.json();
  }

  throw new Error("fetch failed");
}
